// import _ from 'lodash'

export default async function getMenuData() {
  const userStr = localStorage.getItem('user')
  const userData = JSON.parse(userStr)
  if (userData) {
    const { child = [], menu = [], module = [] } = userData

    const routeData = module.map(module => {
      const parent = menu
        .filter(menuItem => menuItem.moduleid === module.moduleid)
        .map(parentItem => {
          const children = child
            .filter(item => item.parentid === parentItem.parentid)
            .map(item => ({
              ...item,
              key: `${parentItem.parentid}-${item.childname}`,
              title: item.childname,
              url: item?.childurl,
            }))
          const returnData = {
            ...parentItem,
            key: `${parentItem.moduleid}-${parentItem.parentname}`,
            title: parentItem.parentname,
          }

          if (children?.length) {
            returnData.children = children
          } else {
            returnData.url = parentItem?.parenturl
          }

          return returnData
        })
      return {
        ...module,
        children: parent,
        category: true,
        title: module.modulename,
        key: module.modulename,
        icon: module.classname,
      }
    })


    routeData.push({
      "appid": "2",
      "moduleid": "15",
      "modulename": "Metabase",
      "moduleurl": "",
      "access": "0",
      "classname": "fe fe-cpu",
      "children": [
         
          {
              "appid": "2",
              "moduleid": "15",
              "parentid": "12",
              "parentname": "Operations",
              "parenturl": "",
              "access": "0",
              "classname": "",
              "key": "15-Operations",
              "title": "Operations",
              "children": [
                  {
                      "appid": "2",
                      "moduleid": "15",
                      "parentid": "12",
                      "childid": "1",
                      "childname": "Inbound SLA",
                      "childurl": "/reports/metabase/dashboard/23",
                      "access": "0",
                      "classname": "",
                      "key": "15-Inbound SLA",
                      "title": "Inbound SLA",
                      "url": "/reports/metabase/dashboard/23"
                  },
                  {
                      "appid": "2",
                      "moduleid": "15",
                      "parentid": "12",
                      "childid": "2",
                      "childname": "Customer SLA Compliance",
                      "childurl": "/reports/metabase/question/143",
                      "access": "0",
                      "classname": "",
                      "key": "15-Customer SLA Compliance",
                      "title": "Customer SLA Compliance",
                      "url": "/reports/metabase/question/143"
                  },
                  {
                    "appid": "2",
                    "moduleid": "15",
                    "parentid": "12",
                    "childid": "3",
                    "childname": "Monthly Customer SLA ",
                    "childurl": "/reports/metabase/question/144",
                    "access": "0",
                    "classname": "",
                    "key": "15-Monthly Customer SLA ",
                    "title": "Monthly Customer SLA ",
                    "url": "/reports/metabase/question/144"
                },
                  {
                    "appid": "2",
                    "moduleid": "15",
                    "parentid": "12",
                    "childid": "4",
                    "childname": "Warehouse SLA Compliance - Warehouse",
                    "childurl": "/reports/metabase/question/141",
                    "access": "0",
                    "classname": "",
                    "key": "15-Warehouse SLA Compliance",
                    "title": "Warehouse SLA Compliance",
                    "url": "/reports/metabase/question/141"
                },
                {
                  "appid": "2",
                  "moduleid": "15",
                  "parentid": "12",
                  "childid": "5",
                  "childname": "Monthly Warehouse SLA ",
                  "childurl": "/reports/metabase/question/145",
                  "access": "0",
                  "classname": "",
                  "key": "15-Monthly Warehouse SLA ",
                  "title": "Monthly Warehouse SLA ",
                  "url": "/reports/metabase/question/145"
              },
                  
              ]
          },
          {
            "appid": "2",
            "moduleid": "16",
            "parentid": "12",
            "parentname": "HR",
            "parenturl": "",
            "access": "0",
            "classname": "",
            "key": "16-HR",
            "title": "HR",
            "children": [
                {
                    "appid": "2",
                    "moduleid": "16",
                    "parentid": "12",
                    "childid": "1",
                    "childname": "HR Overview",
                    "childurl": "/reports/metabase/dashboard/17",
                    "access": "0",
                    "classname": "",
                    "key": "16-HR Overview",
                    "title": "HR Overview",
                    "url": "/reports/metabase/dashboard/17"
                },
                {
                  "appid": "2",
                  "moduleid": "16",
                  "parentid": "12",
                  "childid": "2",
                  "childname": "Attendance",
                  "childurl": "/reports/metabase/dashboard/20",
                  "access": "0",
                  "classname": "",
                  "key": "16-Attendance",
                  "title": "Attendance",
                  "url": "/reports/metabase/dashboard/20"
              },
              {
                "appid": "2",
                "moduleid": "16",
                "parentid": "12",
                "childid": "3",
                "childname": "Employees Overview",
                "childurl": "/reports/metabase/dashboard/21",
                "access": "0",
                "classname": "",
                "key": "16-Employees Overview",
                "title": "Employees Overview",
                "url": "/reports/metabase/dashboard/21"
            },
            {
              "appid": "2",
              "moduleid": "16",
              "parentid": "12",
              "childid": "4",
              "childname": "Offrole Overview",
              "childurl": "/reports/metabase/dashboard/22",
              "access": "0",
              "classname": "",
              "key": "16-Offrole Overview",
              "title": "Offrole Overview",
              "url": "/reports/metabase/dashboard/22"
          },
          {
            "appid": "2",
            "moduleid": "16",
            "parentid": "12",
            "childid": "5",
            "childname": "Attendance Auth Compliance",
            "childurl": "/reports/metabase/question/152",
            "access": "0",
            "classname": "",
            "key": "16-Attendance Auth Compliance",
            "title": "Attendance Auth Compliance",
            "url": "/reports/metabase/question/152"
        },
        {
          "appid": "2",
          "moduleid": "16",
          "parentid": "12",
          "childid": "6",
          "childname": "Absenteeism",
          "childurl": "/reports/metabase/question/154",
          "access": "0",
          "classname": "",
          "key": "16-Absenteeism",
          "title": "Absenteeism",
          "url": "/reports/metabase/question/154"
      },
      {
        "appid": "2",
        "moduleid": "16",
        "parentid": "12",
        "childid": "6",
        "childname": "Employees by Vendor and Role",
        "childurl": "/reports/metabase/question/120",
        "access": "0",
        "classname": "",
        "key": "16-Employees by Vendor and Role",
        "title": "Employees by Vendor and Role",
        "url": "/reports/metabase/question/120"
    }
                
            ]
        },
        {
          "appid": "2",
          "moduleid": "17",
          "parentid": "12",
          "parentname": "BD",
          "parenturl": "",
          "access": "0",
          "classname": "",
          "key": "16-BD",
          "title": "BD",
          "children": [
              {
                  "appid": "2",
                  "moduleid": "17",
                  "parentid": "12",
                  "childid": "1",
                  "childname": "BD Overview",
                  "childurl": "/reports/metabase/dashboard/25",
                  "access": "0",
                  "classname": "",
                  "key": "17-BD Overview",
                  "title": "BD Overview",
                  "url": "/reports/metabase/dashboard/25"
              }
              
          ]
      }
          
      ],
      "category": true,
      "title": "Metabase",
      "key": "Metabase",
      "icon": "fe fe-cpu"
  })

    return routeData
  } else {
    return []
  }
}

// export default async function getMenuData() {
//   return [
//     {
//       category: true,
//       title: 'Dashboards',
//     },
//     {
//       title: 'Finance',
//       key: 'financedashboards',
//       icon: 'fe fe-activity',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Profitability Analysis',
//           key: 'profitabilityanalysis',
//           url: '/dashboard/profitability',
//         },
//       ],
//     },
//     {
//       title: 'Operations',
//       key: 'operationsdashboards',
//       icon: 'fe fe-box',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Overview',
//           key: 'operationsdashboard',
//           url: '/dashboard/operations',
//         },
//         {
//           title: 'Inbound',
//           key: 'inbounddashboard',
//           url: '/dashboard/inbound',
//         },
//         {
//           title: 'Outbound',
//           key: 'outbounddashboard',
//           url: '/dashboard/outbound',
//         },
//       ],
//     },

//     {
//       category: true,
//       title: 'Reports',
//     },

//     {
//       title: 'Operations',
//       key: 'operationsreports',
//       icon: 'fe fe-file',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Inbound Summary',
//           key: 'reportsinboundsummary',
//           url: '/reports/inbound',
//         },
//         {
//           title: 'Outbound Summary',
//           key: 'reportsoutboundsummary',
//           url: '/reports/outbound',
//         },
//         {
//           title: 'Bin Utilization',
//           key: 'reportsbinutilization',
//           url: '/reports/binutilization',
//         },
//         {
//           title: 'Inbound SLA',
//           key: 'reportsinboundsla',
//           url: '/reports/inboundsla',
//         },
//         {
//           title: 'IBD SLA - Warehouse',
//           key: 'reportsinboundslaware',
//           url: '/reports/slawarehouse',
//         },
//         {
//           title: 'IBD SLA - Orders',
//           key: 'reportsinboundslaorders',
//           url: '/reports/inboundslaorders',
//         },
//         {
//           title: 'Outbound SLA',
//           key: 'reportsoutboundsla',
//           url: '/reports/outboundsla',
//         },
//         {
//           title: 'OBD SLA - Warehouse',
//           key: 'reportsoutboundslaware',
//           url: '/reports/obdslawarehouse',
//         },
//         {
//           title: 'OBD SLA - Orders',
//           key: 'reportsoutboundslaorders',
//           url: '/reports/obdslaorders',
//         },
//         {
//           title: 'Inbound TAT',
//           key: 'reportsinboundtat',
//           url: '/reports/inboundtat',
//         },
//         {
//           title: 'Outbound TAT',
//           key: 'reportsoutboundtat',
//           url: '/reports/outboundtat',
//         },
//         // {
//         //   title: 'Inbound Pending',
//         //   key: 'reportsoutboundpa',
//         //   url: '/reports/outboundpa',
//         // },
//         {
//           title: 'Pending Outbound Process',
//           key: 'reportsoutboundpa',
//           url: '/reports/outboundpa',
//         },
//         {
//           title: 'Pending Dispatch Delivery',
//           key: 'pendingdispatch',
//           url: '/reports/pendingdispatch',
//         },
//         // {
//         //   title: 'Outbound Wave pending',
//         //   key: 'reportsoutboundwavepending',
//         //   url: '/reports/outboundwavepending',
//         // },
//         // {
//         //   title: 'Outbound Dispatch pending',
//         //   key: 'reportsoutboundwavepending',
//         //   url: '/reports/outboundwavepending',
//         // },
//         // {
//         //   title: 'Inbound Orders',
//         //   key: 'reportsinboundorders',
//         //   url: '/reports/inbounddetail',
//         // },
//         // {
//         //   title: 'Outbound Orders',
//         //   key: 'reportsoutboundorders',
//         //   url: '/reports/outbounddetail',
//         // },
//         {
//           title: 'Handheld Utilization',
//           key: 'reportshht',
//           url: '/reports/hht',
//         },
//         {
//           title: 'Driver Utilization',
//           key: 'reportsdriver',
//           url: '/reports/driver',
//         },
//         {
//           title: 'Shipway Utilization',
//           key: 'reportshipway',
//           url: '/reports/shipway',
//         },
//         {
//           title: 'Stock Agening',
//           key: 'stockagening',
//           url: '/reports/stockagening',
//         },
//         {
//           title: 'Stock Comparison',
//           key: 'stockcomparison',
//           url: '/reports/stockcomparison',
//         },
//         {
//           title: 'Stock Enquiry',
//           key: 'stockenquiry',
//           url: '/reports/stockenquiry',
//         },
//         // {
//         //   title: 'Trip Details',
//         //   key: 'reportstripdetails',
//         //   url: '/reports/trips',
//         // },
//       ],
//     },
//     // {
//     //   title: 'Financial',
//     //   key: 'financereports',
//     //   icon: 'fe fe-file-text',
//     //   // roles: ['employee'], // set user roles with access to this route

//     //   children: [
//     //     {
//     //       title: 'BD Report',
//     //       key: 'reportsbdreport',
//     //       icon: 'fe fe-file',
//     //       url: '/reports/bdreport',
//     //     }
//     //   ],
//     // },
//     {
//       title: 'Integration',
//       key: 'integrationreports',
//       icon: 'fe fe-file-text',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Exceptions Summary',
//           key: 'execptions',
//           icon: 'fe fe-file',
//           url: '/reports/exceptions',
//         },
//       ],
//     },
//     {
//       title: 'Transaction',
//       key: 'transactions',
//       icon: 'fe fe-help-circle',
//       // roles: ['employee'], // set user roles with access to this route

//       children: [
//         {
//           title: 'Enquiries',
//           key: 'enquiries',
//           icon: 'fe fe-book',
//           url: '/reports/enquiries',
//         },
//       ],
//     },
//   ]
// }
