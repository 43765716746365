const VERSION = 'v1'
export const HOST = process.env.REACT_APP_HOST || 'erp.proconnect.in'
export const HOSTs = process.env.REACT_APP_HOST || 'localhost:44343'
const MONITORING = process.env.REACT_APP_MONITORING || 'Samvaadpro_Monitoring_MS'
// const COMMON = process.env.REACT_APP_COMMON || 'common'
const COMMON = process.env.REACT_APP_COMMON || 'SamvaadPro_Enquiry_MS'
const MIS = process.env.REACT_APP_COMMON || 'UnifiedPortal'
const UTILS = process.env.REACT_APP_UTILS || 'samvaadpro_utility_ms'
const ESHIP = process.env.REACT_APP_ESHIP || 'Samvaadpro_Eships_MS'
const PODUPLOAD = process.env.REACT_APP_PODUPLOAD || 'Samvaadpro_PodUpload_MS'
const ENQUIRY = process.env.REACT_APP_ENQUIRY || 'SamvaadPro_Enquiry_MS'
const SECURITY = process.env.REACT_APP_SECURITY || 'samvaadpro_security_ms'
const APPLICATION = process.env.REACT_APP_APPLICATION || 'Samvaadpro_Application_MS'
const BDREPORT = process.env.REACT_APP_BDREPORT || 'Samvaadpro_bdreports_MS'
const MOBILE = process.env.REACT_APP_MOBILE || 'mobileapp'
export const BASE_URL = `https://${HOST}/${MONITORING}/api/${VERSION}`
export const BASE_COMMON_URL = `https://${HOST}/${COMMON}/api/${VERSION}`
//export const BASE_MIS_URL = `https://${HOSTs}/api/${VERSION}`
export const BASE_MIS_URL = `https://${HOST}/${MIS}/api/${VERSION}`
export const BASE_UTILS_URL = `https://${HOST}/${UTILS}/api/${VERSION}`
//export const BASE_UTILS_URL = `http://${HOSTs}/api/${VERSION}`
export const BASE_ESHIP_URL = `https://${HOST}/${ESHIP}/api/${VERSION}`
export const BASE_PODUPLOAD_URL = `https://${HOST}/${PODUPLOAD}/api/${VERSION}`
export const BASE_ENQUIRY_URL = `https://${HOST}/${ENQUIRY}/api/${VERSION}`
export const BASE_SECURITY_URL = `https://${HOST}/${SECURITY}/api/${VERSION}`
export const BASE_APP_URL = `https://${HOST}/${APPLICATION}/api/${VERSION}`
//export const BASE_APP_URL = `http://${HOSTs}/api/${VERSION}`
export const BASE_BD_URL = `https://${HOST}/${BDREPORT}/api/${VERSION}`
export const BASE_MOBILE_URL = `https://${HOST}/${MOBILE}/api/${VERSION}`
export const BASE_COE_URL = `http://${HOSTs}/api/${VERSION}`
export const DATE_FORMAT = 'YYYY-MM-DD'
export const INPUT_DATE_FORMAT = 'DD-MM-YYYY'
export const pagination = {
  pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
  defaultPageSize: 20,
  position: ['bottomRight'],
  // hideOnSinglePage: true,
  showSizeChanger: true,
  size: 'small',
}
export const colors = ['#388e3c', '#f50']
export const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
export const fileExtension = '.xlsx'
export const XL_DATE_FORMAT = 'YYYYMMDD'
