import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
// import { useQuery } from 'react-query'
import Layout from 'layouts'

import { Loader } from 'components/ui/Loader'
import { useReportTypes } from 'apis'
// import { getAuthToken } from './services/gettoken'

const routes = [
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  //   {
  //     path: '/dashboard/bddashboard',
  //     Component: lazy(() => import('pages/dashboard/alpha')),
  //     exact: true,
  //   },
  //   {
  //     path: '/dashboard/placeholder',
  //     Component: lazy(() => import('pages/dashboard/placeholder')),
  //     exact: true,
  //   },
  {
    path: '/dashboard/operations',
    Component: lazy(() => import('pages/dashboard/operations')),
    exact: true,
  },
  //   {
  //     path: '/dashboard/inbound',
  //     Component: lazy(() => import('pages/dashboard/inbound')),
  //     exact: true,
  //   },
  //   {
  //     path: '/dashboard/outbound',
  //     Component: lazy(() => import('pages/dashboard/outbound')),
  //     exact: true,
  //   },
  //   {
  //     path: '/dashboard/beta',
  //     Component: lazy(() => import('pages/dashboard/beta')),
  //     exact: true,
  //   },

  //   {
  //     path: '/dashboard/gamma',
  //     Component: lazy(() => import('pages/dashboard/gamma')),
  //     exact: true,
  //   },
  //   {
  //     path: '/dashboard/crypto',
  //     Component: lazy(() => import('pages/dashboard/crypto')),
  //     exact: true,
  //   },
  {
    path: '/reports/bddashboard',
    Component: lazy(() => import('pages/dashboard/profitability')),
    exact: true,
  },
  {
    path: '/dashboard/scorecard',
    Component: lazy(() => import('pages/dashboard/Scorecard')),
    exact: true,
  },
  //   {
  //     path: '/reports/bddashboard',
  //     Component: lazy(() => import('pages/dashboard/profitability')),
  //     exact: true,
  //   },

  {
    path: '/reports/inbound',
    Component: lazy(() => import('pages/reports/inbound')),
    exact: true,
  },
  {
    path: '/reports/outbound',
    Component: lazy(() => import('pages/reports/outbound')),
    exact: true,
  },
  {
    path: '/reports/outboundpa',
    Component: lazy(() => import('pages/reports/outboundpa')),
    exact: true,
  },
  {
    path: '/reports/inboundpa',
    Component: lazy(() => import('pages/reports/inboundpa')),
    exact: true,
  },
  {
    path: '/reports/inbounddetail',
    Component: lazy(() => import('pages/reports/inbounddetail')),
    exact: true,
  },
  {
    path: '/reports/outbounddetail',
    Component: lazy(() => import('pages/reports/outbounddetail')),
    exact: true,
  },
  {
    path: '/reports/outboundwavepending',
    Component: lazy(() => import('pages/reports/outboundwavepending')),
    exact: true,
  },
  {
    path: '/reports/pendingdispatch',
    Component: lazy(() => import('pages/reports/pendingdispatch')),
    exact: true,
  },
  {
    path: '/reports/hht',
    Component: lazy(() => import('pages/reports/hht')),
    exact: true,
  },

  {
    path: '/master/skumaster',
    Component: lazy(() => import('pages/reports/skumaster')),
    exact: true,
  },
  {
    path: '/master/binmaster',
    Component: lazy(() => import('pages/reports/binmaster')),
    exact: true,
  },
  {
    path: '/master/warehousespace',
    Component: lazy(() => import('pages/reports/warehousespace')),
    exact: true,
  },
  {
    path: '/master/customerspace',
    Component: lazy(() => import('pages/reports/customerspace')),
    exact: true,
  },
  {
    path: '/master/usermanagement',
    Component: lazy(() => import('pages/master/usermanagement')),
    exact: true,
  },
  {
    path: '/transactions/dispatchrepush',
    Component: lazy(() => import('pages/transactions/dispatchrepush')),
    exact: true,
  },
  {
    path: '/transactions/deliveryrepush',
    Component: lazy(() => import('pages/transactions/deliveryrepush')),
    exact: true,
  },
  {
    path: '/transactions/podupload',
    Component: lazy(() => import('pages/transactions/podupload')),
    exact: true,
  },
  {
    path: '/transactions/deliveryandpod',
    Component: lazy(() => import('pages/transactions/deliveryandpod')),
    exact: true,
  },
  {
    path: '/reports/driver',
    Component: lazy(() => import('pages/reports/driver')),
    exact: true,
  },
  {
    path: '/reports/shipway',
    Component: lazy(() => import('pages/reports/shipway')),
    exact: true,
  },
  {
    path: '/reports/docktostock',
    Component: lazy(() => import('pages/reports/docktostock')),
    exact: true,
  },
  {
    path: '/reports/stocktodock',
    Component: lazy(() => import('pages/reports/stocktodock')),
    exact: true,
  },
  {
    path: '/reports/obdstatus',
    Component: lazy(() => import('pages/reports/obdstatus')),
    exact: true,
  },
  {
    path: '/reports/rateandbilling',
    Component: lazy(() => import('pages/reports/rateandbilling')),
    exact: true,
  },
  {
    path: '/reports/nua/ibdsummary',
    Component: lazy(() => import('pages/reports/customer/ibdsummary')),
    exact: true,
  },
  {
    path: '/reports/nua/obdsummary',
    Component: lazy(() => import('pages/reports/customer/obdsummary')),
    exact: true,
  },
  {
    path: '/reports/nua/stocksummary',
    Component: lazy(() => import('pages/reports/customer/stocksummary')),
    exact: true,
  },
  {
    path: '/reports/nua/pendingdispatch',
    Component: lazy(() => import('pages/reports/customer/pendingdispatch')),
    exact: true,
  },
  {
    path: '/reports/wmsfollowup',
    Component: lazy(() => import('pages/reports/customer/wmsfollowup')),
    exact: true,
  },
  {
    path: '/reports/redington/amgreport',
    Component: lazy(() => import('pages/reports/customer/amgreport')),
    exact: true,
  },
  // {
  //   path: '/reports/trips',
  //   Component: lazy(() => import('pages/reports/driver/trips')),
  //   exact: true,
  // },
  {
    path: '/reports/inboundsla',
    Component: lazy(() => import('pages/reports/inbound/slareport')),
    exact: true,
  },
  {
    path: '/reports/rilinboundsla',
    Component: lazy(() => import('pages/reports/inbound/rilslareport')),
    exact: true,
  },
  {
    path: '/reports/slawarehouse',
    Component: lazy(() => import('pages/reports/inbound/slawarehouse')),
    exact: true,
  },
  {
    path: '/reports/rilslawarehouse',
    Component: lazy(() => import('pages/reports/inbound/rilslawarehouse')),
    exact: true,
  },
  {
    path: '/reports/inboundslaorders',
    Component: lazy(() => import('pages/reports/inbound/slaorders')),
    exact: true,
  },
  {
    path: '/reports/rilinboundslaorders',
    Component: lazy(() => import('pages/reports/inbound/rilslaorders')),
    exact: true,
  },
  
  {
    path: '/reports/obdslawarehouse',
    Component: lazy(() => import('pages/reports/outbound/obdslawarehouse')),
    exact: true,
  },
  {
    path: '/reports/rilobdslawarehouse',
    Component: lazy(() => import('pages/reports/outbound/rilobdslawarehouse')),
    exact: true,
  },
  {
    path: '/reports/obdslaorders',
    Component: lazy(() => import('pages/reports/outbound/obdslaorders')),
    exact: true,
  },
  {
    path: '/reports/rilobdslaorders',
    Component: lazy(() => import('pages/reports/outbound/rilobdslaorders')),
    exact: true,
  },
  {
    path: '/reports/wavepending',
    Component: lazy(() => import('pages/reports/customer/wavepending')),
    exact: true,
  },
  {
    path: '/reports/pendingactivity',
    Component: lazy(() => import('pages/reports/pendingactivity')),
    exact: true,
  },
  {
    path: '/reports/bdreports',
    Component: lazy(() => import('pages/reports/bdreport')),
    exact: true,
  },
  {
    path: '/reports/inboundtat',
    Component: lazy(() => import('pages/reports/inbound/tatreport')),
    exact: true,
  },
  {
    path: '/reports/outboundtat',
    Component: lazy(() => import('pages/reports/outbound/tatreport')),
    exact: true,
  },
  {
    path: '/reports/outboundsla',
    Component: lazy(() => import('pages/reports/outbound/slareport')),
    exact: true,
  },
  {
    path: '/reports/riloutboundsla',
    Component: lazy(() => import('pages/reports/outbound/rilslareport')),
    exact: true,
  },
  {
    path: '/reports/binutilization',
    Component: lazy(() => import('pages/reports/binutilization')),
    exact: true,
  },
  {
    path: '/reports/stockagening',
    Component: lazy(() => import('pages/reports/stockagening')),
    exact: true,
  },
  {
    path: '/reports/stockcomparison',
    Component: lazy(() => import('pages/reports/stockcomparison')),
    exact: true,
  },
  {
    path: '/reports/dispatchdashboard',
    Component: lazy(() => import('pages/reports/dispatchdashboard')),
    exact: true,
  },
  //   {
  //     path: '/reports/dailymisentry',
  //     Component: lazy(() => import('pages/reports/dailymisentry')),
  //     exact: true,
  //   },
  {
    path: '/reports/stockenquiry',
    Component: lazy(() => import('pages/reports/stockenquiry')),
    exact: true,
  },

  {
    path: '/reports/exceptions',
    Component: lazy(() => import('pages/reports/exceptions')),
    exact: true,
  },
  {
    path: '/reports/exceptiondetails',
    Component: lazy(() => import('pages/reports/exceptions/exceptiondetails')),
    exact: true,
  },
  {
    path: '/reports/enquiries',
    Component: lazy(() => import('pages/reports/enquiries')),
    exact: true,
  },
  {
    path: '/reports/nearbreachibd',
    Component: lazy(() => import('pages/reports/nearbreach/inbound')),
    exact: true,
  },
  {
    path: '/reports/nearbreachobd',
    Component: lazy(() => import('pages/reports/nearbreach/outbound')),
    exact: true,
  },
  {
    path: '/reports/attendancematrix',
    Component: lazy(() => import('pages/reports/attendancematrix')),
    exact: true,
  },
  {
    path: '/transactions/tmsvssap',
    Component: lazy(() => import('pages/transactions/tmsvssap')),
    exact: true,
  },
  {
    path: '/transactions/obdtask',
    Component: lazy(() => import('pages/transactions/outboundtask')),
    exact: true,
  },
  {
    path: '/reports/inventorymatrix',
    Component: lazy(() => import('pages/reports/inventorymatrix')),
    exact: true,
  },
  {
    path: '/reports/docketenquiry',
    Component: lazy(() => import('pages/reports/orderdocketenquiry')),
    exact: true,
  },
  {
    path: '/reports/gateledger',
    Component: lazy(() => import('pages/reports/gateledger')),
    exact: true,
  },
  {
    path: '/reports/ibdobdreport',
    Component: lazy(() => import('pages/reports/ibdobdreport')),
    exact: true,
  },
  {
    path: '/reports/eshipservice',
    Component: lazy(() => import('pages/transactions/eship/eshipservice')),
    exact: true,
  },
  {
    path: '/reports/eshipshipment',
    Component: lazy(() => import('pages/transactions/eship/eshipshipment')),
    exact: true,
  },
  {
    path: '/reports/metabase/:reporttype/:id',
    Component: lazy(() => import('pages/reports/metabase')),
    exact: true,
  },
  {
    path: '/reports/coereport',
    Component: lazy(() => import('pages/reports/coereport')),
    exact: true,
  },
  // {
  //   path: '/reports/enquirydetails',
  //   Component: lazy(() => import('pages/reports/enquiries/enquirydetails')),
  //   exact: true,
  // },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  useReportTypes()
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    
                  <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={<Loader />}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
