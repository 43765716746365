import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { LiveChatLoaderProvider, Chatwoot } from 'react-live-chat-loader'

// import TopBar from 'components/connectone/layout/TopBar'
import Breadcrumbs from 'components/connectone/layout/Breadcrumbs'
import Menu from 'components/connectone/layout/Menu'
import Footer from 'components/connectone/layout/Footer'
import UserMenu from 'components/connectone/layout/TopBar/UserMenu'
import SupportChat from 'components/connectone/layout/SupportChat'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
}) => {
  return (
    <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
      <Layout
        className={classNames({
          cui__layout__contentMaxWidth: isContentMaxWidth,
          cui__layout__appMaxWidth: isAppMaxWidth,
          cui__layout__grayBackground: isGrayBackground,
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
        })}
      >
        {
          /*
        window.location.host !== 'app.proconnectonline.in' && <SupportChat />
       
        window.location.host !== 'app.proconnectonline.in' && 
        <LiveChatLoaderProvider
        //providerKey="ZzojMicm1pHejhhygvZbR7XK"
        providerKey="kq9DKdUDZ7kX285wYhAZEec4"
        provider="chatwoot"
      >
       
        <Chatwoot />
      </LiveChatLoaderProvider>
      */
        }
        <Menu />
        <Layout>
          {/* <Layout.Header
            className={classNames('cui__layout__header', {
              cui__layout__fixedHeader: isTopbarFixed,
              cui__layout__headerGray: isGrayTopbar,
            })}
          >
        </Layout.Header> */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Breadcrumbs />
            <div style={{ margin: '8px 1rem' }}>
              <UserMenu />
            </div>
          </div>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="cui__utils__content" style={{ padding: '4px 0.5rem' }}>
              {children}
            </div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
